.last-updated {
  font-size: 13px;
  color: var(--gray-two);
  font-weight: 600;
  text-align: center;
  cursor: help;
  display: flex;
}

.last-updated > span {
  order: 2;
}

.last-updated.hide {
  display: none;
}

@media only screen and (max-device-width: 767px) {
  .last-updated {
    line-height: 1.75;
  }
}
