.tournament-switch {
  position: absolute;
  top: 2rem;
  left: 2rem;
  display: flex;
}

/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .tournament-switch {
    top: 41px;
    left: 16px;
  }
}

/*Mobile*/
@media only screen and (max-device-width: 767px) {
  .tournament-switch {
    top: 1.25rem;
    left: 0.25rem;
  }
}

.tournament-option {
  margin: 0 0.625rem;
}

.tournament-option input {
  display: none;
}

.tournament-option label {
  cursor: pointer;
}

.tournament-option input ~ label svg {
  margin-right: 0.5rem;
  width: 0.875rem;
}

.tournament-option input ~ label svg path {
  fill: var(--axlOrange);
}

.tournament-option input:checked ~ label {
  background: var(--axlOrange);
  border: none;
  padding: 0.5rem;
  border-radius: 0.625rem;
  color: var(--pure-white);
  font-weight: 600;
}

.tournament-option input:checked ~ label svg {
  display: none;
}
