.info-popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 24px;
  height: 24px;
  text-align: center;
}

.info-popup svg {
  display: inline-block;
  padding: 4px;
}

.info-popup .caption {
  display: none;
  background-color: var(--gold);
  padding: 1rem;
  position: absolute;
  border-radius: 8px;
  box-shadow: 0px 0px 31px var(--black-two-c);
  width: 200px;
  left: -100px;
  bottom: 45px;
  text-align: left;
  font-size: 12px;
}

.info-popup .caption b {
  font-style: italic;
  text-decoration: underline;
}

.info-popup .caption b:not(:first-of-type) {
  display: inline-block;
  margin-top: 8px;
}

.info-popup svg path {
  fill: var(--bg1);
  transition: all 0.1s ease;
}

.info-popup .caption::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: var(--gold) transparent transparent transparent;
  position: absolute;
  bottom: -10px;
  left: 100px;
}

.info-popup:hover .caption {
  display: block;
}

.info-popup:hover svg path {
  fill: var(--gold);
}

.stats-dial {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

body:not(.dark-mode) .info-popup .caption::after {
  border-color: var(--pos-3) transparent transparent transparent;
}

body:not(.dark-mode) .info-popup svg path {
  fill: var(--light-bonus);
}

body:not(.dark-mode) .info-popup:hover svg path {
  fill: var(--pos-3);
}

body:not(.dark-mode) .info-popup .caption {
  background-color: var(--pos-3);
  color: var(--pure-white);
}
