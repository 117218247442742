.faq-item {
  display: flex;
  align-items: baseline;
  justify-content: center;
  box-shadow: 0px 0px 22px rgb(0 0 0 / 30%), 0px 0px 12px 0px rgb(0 0 0 / 22%);
  backdrop-filter: blur(5px);
  background: var(--header);
  margin-bottom: 8px;
  color: var(--pure-white);
  padding: 0.75rem 1rem;
  flex-direction: column;
  line-height: 1.5;
  border-radius: 0.625rem;
}

.faq-item .question {
  font-weight: 600;
  color: var(--axlOrange);
  margin-bottom: 0.5rem;
}

.faq-item svg {
  width: 1.5rem;
  margin-right: 0.75rem;
}

.faq-item svg path { fill: var(--bg1); }
