.about-us {
  display: flex;
}

.about-us > div {
  background: var(--header);
  color: var(--pure-white);
  text-align: center;
  font-weight: 400;
  padding: 2rem 6rem;
  box-shadow: 0px 0px 22px rgb(0 0 0 / 30%), 0px 0px 12px 0px rgb(0 0 0 / 22%);
  backdrop-filter: blur(5px);
  border-radius: 0.625rem;
}

.about-us > div:first-of-type {
  max-width: 45%;
  margin-right: 0.5rem;
}

@media only screen and (max-device-width: 767px) {
  .about-us {
    display: block;
  }

  .about-us > div {
    padding: 2rem;
  }

  .about-us > div:first-of-type {
    max-width: none;
    margin-right: 0;
  }

  .about-us > div:last-of-type {
    margin-top: 0.5rem;
  }
}

.team-member img {
  width: 100%;
  border-radius: 50%;
}

.user-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid var(--yellow);
  padding: 5px;
}

.about-us p {
  line-height: 2;
}

.team-member {
  display: inline-flex;
  margin: 0 1rem;
}

.team-member a {
  font-weight: 700;
  text-decoration: none;
  color: var(--pure-white);
}

.team-member h3 {
  text-align: center;
}

.team-member h3 svg {
  margin-right: 0.5rem;
}

.team-excerpt {
  padding: 1rem !important;
}

.team-excerpt h2 {
  padding-bottom: 2rem;
}
