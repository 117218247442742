.predictions {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(15rem,1fr));
  grid-gap: 2rem;
  margin-top: 1.5rem;
}

.prediction-card {
  background: var(--header);
  padding: 1rem;
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  border-radius: 0.625rem;
  overflow: hidden;
  box-shadow:  0px 0px 22px rgb(0 0 0 / 30%), 0px 0px 12px 0px rgb(0 0 0 / 22%);
  backdrop-filter: blur(5px);
}

.prediction-card > span {
  display: inline-block;
  margin: 0 1.25rem;
  color: var(--pure-white);
  font-weight: 700;
  font-size: 1.25rem;
}

.prediction-card > div {
  display: inline-flex;
  align-items: center;
  color: var(--pure-white);
  font-weight: 700;
  position: relative;
  flex-direction: column;
}

.prediction-card > div.match-result {
  grid-column: 1/-1;
  margin-top: 0.625rem;
  padding-top: 0.625rem;
  border-top: 1px dashed var(--bg1);
  flex-direction: row;
}

.prediction-card > div.match-result > span {
  font-style: italic;
}

.prediction-card > span.number {
  right: -35px;
  color: var(--bg2-18);
  font-size: 8.675rem;
  top: 50%;
  font-weight: normal;
}

.prediction-card .match-points {
  grid-column: 1/-1;
  margin-top: 0.625rem;
}

.icons::after {
  content: '';
  display: inline-block;
  width: calc(80%);
  height: 2px;
  background: var(--bg2);
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
}

.prediction-card > div:not(.win) > .team-name-icon {
  filter: grayscale(1);
  opacity: 0.5;
}

.team-name-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icons {
  margin-top: 1rem;
  position: relative;
}

.icons svg {
  margin: 0 10px;
}

.win.prediction svg path {
  fill: var(--axlOrange);
}

.win.prediction .minus.highlight {
  display: none;
}

.win svg path {
  fill: var(--yellow);
}

.prediction svg path {
  fill: var(--bg2);
}

.wwcd {
  box-shadow: 0 19px 38px #83ff5a, 0 15px 12px black;
}

.prediction-card.waiting-result {
  box-shadow: 0px 0px 6px 6px var(--yellow);
}

.bonus-question {
  display: block !important;
  font-size: 12px;;
  grid-column: 1/-1;
  margin-top: 1rem;
  border-top: 1px dashed var(--bg1);
  padding-top: 1rem;
}

.bonus-question svg {
  font-size: 1rem;
  margin-right: 0.5rem;
}

.question svg path {
  fill: var(--bg1);
}

.bonus-question svg.result {
  margin-left: 1rem;
}

.bonus-question svg path {
  fill: var(--bg1);
}

.bonus-question > .answer {
  margin-right: 1.25rem;
}

.bonus-question > .question {
  margin-bottom: 1rem;
  border-bottom: 1px dashed var(--bg1);
  padding-bottom: 1rem;
}

.user-answer.win > span {
  margin-right: 0.5rem;
}

.user-answer:not(.win) {
  opacity: 0.4;
}

.user-answer > .b {
  margin-left: 1rem;
}

.user-answer.win > svg:last-of-type path {
  fill: var(--yellow);
}

.bonus-question > .answer,
.bonus-question > .user-answer {
  display: inline-flex;
  width: auto;
  padding: 4px 0;
}

.bonus-question > div {
  width: 100%;
  display: flex;
  align-items: center;
  color: #ccc;
}

.prediction-card:not(.wwcd) .win .w {
  opacity: 0.5;
}

/*Mobile*/
@media only screen and (max-device-width: 767px) {
  .predictions {
    grid-gap: 12px 2rem;
    margin-top: 1rem;
  }
}
