.nav-bar {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 22px rgb(0 0 0 / 30%), 0px 0px 12px 0px rgb(0 0 0 / 22%);
  position: sticky;
  top: 0;
  z-index: 2;
  background: var(--header);
  border-radius: 0.625rem;
  backdrop-filter: blur(5px);
}

.nav-bar a {
  color: var(--pure-white);
  font-weight: bold;
  text-decoration: none;
  margin: 0 1rem;
  text-transform: uppercase;
}

.nav-bar a span {
  padding-left: 0.5rem;
}

@media only screen and (max-device-width: 767px) {
  .nav-bar a span {
    display: none;
  }
}


.nav-bar a.is-active {
  color: var(--border);
}
