.user-header {
  background: var(--header);
  padding: 1rem;
  position: relative;
  overflow: hidden;
  box-shadow:  0px 0px 22px rgb(0 0 0 / 30%), 0px 0px 12px 0px rgb(0 0 0 / 22%);
  backdrop-filter: blur(5px);
  border-radius: 0.625rem;
}

.user-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.name-image {
  color: var(--bg1);
  display: grid;
  grid-template-columns: 116px 1fr;
  grid-gap: 14px;
  margin: 0 1.25rem;
}

.user-title-stats {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.name-image .user-image {
  display: flex;
  width: 100px;
  height: 100px;
  margin-right: 1rem;
  z-index: 1;
}

.name-image h1 {
  text-shadow: 2px 3px 3px var(--header);
  align-self: center;
  z-index: 1;
}

.stats-dial {
  margin: 0 1.25rem;
}

.stats {
  grid-column: 2/3;
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 24px;
  z-index: 1;
}

.stats > div:not(.rank) {
  display: inline-flex;
  align-items: center;
}

.stats > div:not(.rank) .label {
  margin-right: 0.5rem;
  color: var(--pure-white);
  min-width: 74px;
}

.stats > div:not(.rank) .label svg {
  min-width: 20px;
}

.rank {
  align-self: center;
  justify-self: center;
  position: absolute;
  left: 2rem;
  top: -4rem;
  width: 100px;
  text-align: center;
}

.rank > span {
  color: var(--bg2-18);
  font-size: 24rem;
  position: relative;
  display: block;
}

.rank > span > span:nth-child(2) {
  position: absolute;
  font-size: 3rem;
  top: 0;
  right: -2rem;
}

span.pos-1 {
  color: var(--gold);
}

span.pos-2 {
  color: var(--silver);
}

span.pos-3 {
  color: var(--pos-3);
}

.stats .accuracy {
  grid-column: 1/-1;
}

/*Mobile*/
@media only screen and (max-device-width: 767px) {
  .user-header {
    grid-template-columns: 1fr;
  }

  .user-header > div,
  .name-image {
    margin: 0;
  }

  .name-image .user-image {
    width: 50px;
    height: 50px;
  }

  .name-image h1 {
    font-size: 1.5rem;
    margin: 0;
    max-width: 72%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .name-image {
    grid-template-columns: 68px 1fr;
    grid-row-gap: 1rem;
  }

  .rank {
    position: absolute;
    right: 4rem;
    top: 0.625rem;
    opacity: 0.5;
  }

  .rank > span {
    font-size: 14.5rem;
  }

  .rank > span > span:nth-child(2) {
    right: -15px;
    font-size: 20px;
  }

  .user-title-stats {
    display: block;
  }

  .stats {
    grid-column: 1/-1;
    width: 82%;
    margin-top: 0rem;
    justify-self: center;
  }

  .stats-dial,
  .stats > div:not(.rank) {
    justify-self: center;
  }
}

/* Tablet. */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .user-header {
    grid-template-columns: 1fr 1fr;
  }

  .user-header .user-progress-chart {
    grid-column: 1/-1;
  }

  .name-image {
    margin: 0;
    grid-template-columns: 68px 1fr;
    grid-row-gap: 2rem;
  }

  .name-image .user-image {
    width: 50px;
    height: 50px;
  }

  .name-image h1 {
    font-size: 1.5rem;
    margin: 0;
  }

  .stats {
    grid-column: 1/-1;
    justify-content: center;
  }

  .stats > div:not(.rank) .label {
    display: block;
  }

  .stats > div:not(.rank) .label svg {
    margin-right: 0.5rem;
  }

  .stats-dial {
    margin: 0;
  }

  .rank {
    left: 2rem;
    top: 19rem;
  }

  .rank > span {
    font-size: 20rem;
  }

  .rank > span > span:nth-child(2) {
    position: absolute;
    font-size: 2rem;
    top: -9px;
    right: -22px;
  }
}
