.results-row {
  display: grid;
  grid-template-columns: 0.5fr minmax(3rem, 10rem) minmax(12rem, 1fr) 1fr 1fr 1fr 1fr 1fr 1fr;
  height: 2.5rem;
  background: var(--header);
  color: var(--pure-white);
  margin-bottom: 0.125rem;
  align-items: center;
  overflow: hidden;
  box-shadow:  0px 0px 22px rgb(0 0 0 / 30%), 0px 0px 12px 0px rgb(0 0 0 / 22%);
  backdrop-filter: blur(5px);
  position: relative;
  border-radius: 0.625rem;
}

.mobile-only-label {
  display: none;
}

.empty-row {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  background: var(--header);
  color: var(--pure-white);
}

.results-row.header {
  color: var(--pure-white);
  font-weight: 700;
  margin-bottom: 0.5rem;
  cursor: pointer;
  position: sticky;
  top: 42px;
  z-index: 2;
  background: var(--header);
  box-shadow: 0px 0px 22px rgb(0 0 0 / 30%), 0px 0px 12px 0px rgb(0 0 0 / 22%);
  backdrop-filter: blur(5px);
}

.results-row > div {
  padding: 0 0.625rem;
}

.results-row:not(.header) {
  transition: transform 0.3s ease;
}

.results-row:not(.header):hover {
  transform: scale(1.015);
}

.name {
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.name a {
  color: var(--pure-white);
  text-decoration: none;
}

.name a:hover {
  color: var(--axlOrange);
  text-decoration: underline;
}

.highlight {
  color: var(--pure-white);
  font-size: 0.75rem;
  min-width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 50%;
  margin: 0 0.25rem;
  padding: 0 !important;
}

.avatar .user-image {
  width: 20px;
  height: 20px;
  border-width: 2px;
  padding: 2px;
  display: flex;
  align-items: center;
}

.avatar .pos,
.rank .pos {
  color: var(--bg2-52);
  font-size: 27px;
  font-weight: 600;
  cursor: pointer;
}

.avatar .user-image.no-image span {
  font-size: 0.75rem;
}

.w {
  background: var(--axlOrange);
}

.minus {
  background: var(--minus);
}

body:not(.dark-mode) .minus {
  background: var(--minus-dark);
}

.l {
  background: var(--bg2);
}

.acc {
  background: var(--acc);
}

.b {
  background: var(--gold);
  color: var(--black-two);
}

.trivia {
  background-color: var(--trivia-pink);
  color: var(--pure-white);
}

.skew {
  transform: skewX(-10deg);
  border-radius: 0;
  padding: 0 7px !important;
}

.t {
  background: var(--bg1);
  color: var(--black-two);
}

.a {
  background: var(--gray);
  color: var(--black-three);
}

.results-row > .played,
.results-row > .best-streak,
.results-row > .win-percentage,
.results-row > .item,
.results-row > .avatar,
.results-row > .form,
.results-row > .bonus,
.results-row > .total-points {
  justify-self: center;
  text-align: center;
}

.name svg path {
  fill: var(--axlOrange);
}

.trivia-points svg path {
  fill: var(--trivia-pink);
}

.won svg path {
  fill: var(--gold);
}

.lost svg path {
  fill: var(--bg2);
}

.win-percentage svg path{
  fill: var(--green);
}

.bonus svg path {
  fill: var(--yellow);
}

.accuracy svg path {
  fill: var(--acc);
}

.total-points svg path {
  fill: var(--bg1);
}

.best-streak svg path {
  fill: var(--axlOrange);
}

.avatar .pos.pos-1,
.rank .pos.pos-1 {
  color: var(--gold);
}

.pos:not(.pos-1, .pos-2, .pos-3) {
  opacity: 0.2;
}

.avatar .pos.pos-2,
.rank .pos.pos-2 {
  color: var(--silver);
}

.avatar .pos.pos-3,
.rank .pos.pos-3 {
  color: var(--pos-3);
}

.pos-1,
.pos-2,
.pos-3 {
  opacity: 0.7;
}

.last-5 {
  display: inline-flex;
  flex-direction: row-reverse;
}

.form svg,
.name svg {
  margin-right: 0.5rem;
}

.header .position {
  display: none;
}

@media only screen and (min-device-width: 1025px) {
  .bonus svg,
  .total-points svg,
  .best-streak svg,
  .win-percentage svg,
  .won svg,
  .lost svg,
  .accuracy svg,
  .trivia-points svg {
    margin-right: 0.5rem;
  }
}

@media only screen and (min-device-width: 768px) {
  .position {
    position: absolute;
    right: -11px;
    font-size: 23px;
    font-weight: 400;
    text-align: right;
  }
}

/* Tablet. */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .results-row {
    grid-template-columns: 3rem 9rem 11rem 70px 70px 0.35fr 0.5fr 4rem 4rem;
  }

  .results-table {
    font-size: 0.875rem;
  }

  .results-row.header {
    height: 3rem;
  }

  .highlight {
    font-size: 0.75rem;
  }

  .bonus span:first-of-type,
  .total-points span:first-of-type,
  .best-streak span:first-of-type,
  .win-percentage span:first-of-type,
  .won span:first-of-type,
  .lost span:first-of-type {
    display: none;
  }
}

/*Mobile*/
@media only screen and (max-device-width: 767px) {
  .results-row {
    height: auto;
    grid-template-columns: 3.125rem 1fr 1fr;
    grid-gap: 0.5rem;
    padding: 0.625rem 0.625rem 1.25rem;
    margin-bottom: 0.5rem;
    overflow: hidden;
    line-height: 1.5;
  }

  .position {
    position: absolute;
    right: 14px;
    font-size: 45px;
    top: 0;
  }

  .bonus svg,
  .total-points svg,
  .best-streak svg,
  .win-percentage svg,
  .won svg,
  .lost svg {
    margin-right: 0.5rem;
  }

  .results-row .name,
  .results-row .form {
    grid-column: 2/4;
    display: inline-flex;
    align-items: center;
  }

  .form svg {
    margin-right: 0.5rem;
    width: 10px !important;
    font-size: 13px;
    min-width: 10px !important;
  }

  .form .mobile-only-label {
    margin-right: 0.25rem;
  }

  .results-row .item-w ,
  .results-row .best-streak,
  .results-row .bonus {
    grid-column: 2/3;
    display: inline-flex;
    align-items: center;
    justify-content: left;
  }

  .results-row .item-l,
  .results-row .win-percentage,
  .results-row .total-points {
    grid-column: 3/4;
    display: inline-flex;
  }

  .avatar .user-image {
    width: 2.5rem;
    height: 2.5rem;
  }

  .mobile-only-label {
    display: block;
    margin-right: 0.25rem;
  }

  .mobile-only-label svg {
    min-width: 20px;
  }

  .header.results-row {
    display: none;
  }

  .results-row > div {
    justify-self: auto !important;
    text-align: left !important;
    padding: 0;
  }

  .avatar .pos,
  .rank .pos {
    position: absolute;
    font-size: 100px;
    font-weight: 400;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }

  .best-streak {
    color: var(--axlOrange);
  }

  .win-percentage {
    color: green;
  }

  .results-row > .rank {
    position: absolute;
    top: 46%;
    right: -28px;
    font-size: 220px;
    transform: translateY(-50%);
    color: var(--bg2-18);
  }

  .bonus {
    color: var(--yellow);
  }

  .total-points {
    color: var(--bg1);
  }

  .name {
    font-size: 20px;
    font-weight: bold;
  }
}
