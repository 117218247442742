.page-title-block {
  background: var(--header);
  color: var(--pure-white);
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  box-shadow: 0px 0px 22px rgb(0 0 0 / 30%), 0px 0px 12px 0px rgb(0 0 0 / 22%);
  position: relative;
  border-radius: 0.625rem;
  backdrop-filter: blur(5px);
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .page-title-block {
    margin: 0 0 0.5rem;
  }

  h1.page-title {
    font-size: 1.5rem;
  }

  .page-title-block svg {
    width: 12rem;
  }
}

/*Mobile*/
@media only screen and (max-device-width: 767px) {
  .page-title-block svg {
    width: 10rem;
  }

  h1.page-title {
    font-size: 1.125rem;
  }

  .page-title-block {
    padding: 4rem 1.5rem 1.5rem;
  }
}

h1.page-title {
  text-align: center;
}

.page-title-block svg {
  max-width: 14rem;
  height: auto;
}
