.user-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid var(--axlOrange);
  padding: 5px;
}

.user-image img {
  width: 100%;
  border-radius: 50%;
}

.user-image.no-image > span {
  display: inline-flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--bg1);
  color: var(--axlOrange);
  font-weight: bold;
  font-size: 113px;
  text-transform: capitalize;
  text-shadow: 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%);
}

.user-title-stats .user-image.no-image > span {
  font-size: 30px;
}

.podium .user-image.no-image > span {
  font-size: 15px;
}

@media only screen and (min-device-width: 768px) {
  .user-title-stats .user-image.no-image > span {
    font-size: 40px;
  }
  
  .podium .user-image.no-image > span {
    font-size: 30px;
  }
}

@media only screen and (min-device-width: 1024px) {
  .user-title-stats .user-image.no-image > span {
    font-size: 60px;
  }
}