.sun-moon {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

/*Mobile*/
@media only screen and (max-device-width: 767px) {
  .sun-moon {
    top: 1.25rem;
    right: 1rem;
  }
}

.sun-moon input[type=checkbox] {
  display: none;
}

.sun-moon svg {
  width: 2rem !important;
  cursor: pointer;
}

.sun-moon svg path {
  fill: var(--axlOrange);
}

.dark-mode .sun-moon svg path {
  fill: var(--silver);
}
