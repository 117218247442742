.help-text {
  background: var(--yellow);
  color: var(--pure-black);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;
  justify-content: center;
  box-shadow: 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%);
  display: flex;
  padding: 0.75rem;
  border-radius: 0.625rem;
}

.help-text svg {
  margin-right: 0.625rem;
}

@media only screen and (max-device-width: 767px) {
  .charts .trendlines.a-chart {
    display: none;
  }
}
