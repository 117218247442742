@font-face {
  font-family: 'Quicksand';
  src: url('./fonts/Quicksand-Regular.ttf')  format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./fonts/Quicksand-SemiBold.ttf')  format('truetype');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./fonts/Quicksand-Bold.ttf')  format('truetype');
  font-weight: 700;
  font-display: swap;
}

:root {
  --bg2: #2176ae;
  --bg2-18: rgb(33 118 174 / 18%);
  --bg2-52: rgb(33 118 174 / 52%);
  --bg1: #57b8ff;
  --border: #fc4c02;
  --pure-white: #fff;
  --pure-black: #000;
  --black-two: #222;
  --black-three: #333;
  --black-two-c: #2c2c2c;
  --pure-white-80: rgb(255 255 255 / 80%);
  --pure-white-39: rgb(255 255 255 / 39%);
  --win: #462255;
  --loss: #dd4b1a;
  --header: rgb(12 27 51 / 80%);
  --header-90: rgb(12 27 51 / 90%);
  --gray: #ccc;
  --gray-two: #666;
  --yellow: #ddd92a;
  --blue-25: rgb(115 28 228 / 25%);
  --axlOrange: #fc4c02;
  --minus: #1c00ff;
  --minus-dark: #222620;
  --acc: #0046ff;
  --trivia-pink: rgb(233, 72, 233);
  --green: green;
  --silver: silver;
  --gold: gold;
  --transparent: rgb(255 255 255 / 1%);

  /* Dropdown */
  --s-menu: #00ffc4;
  --s-menu-placeholder: rgb(33 118 174 / 52%);

  /* Light mode. */
  --bg3: rgb(0 255 196 / 70%);
  --bg3-80: rgb(0 255 196 / 80%);
  --light-text: #333;
  --light-bonus: blueviolet;
  --light-absent: #ff8080;
  --light-bg1: #cc8484;
  --light-bg2: #c79d94;

  /* Position color */
  --pos: #a7a7a7;
  --pos-1: #bda003;
  --pos-2: #3c9ad2;
  --pos-3: chocolate;

  /* Toast colors */
  --toastify-color-dark: hsl(18deg 98% 50% / 90%) !important;
  --toastify-text-color-dark: var(--pure-white) !important;
  --toastify-color-success: var(--pure-white) !important;
}

body {
  background-color: var(--bg1);
  height: 100vh;
  font-family: 'Quicksand', sans-serif;
  max-width: 80.25rem;
  margin: 0 auto;
}

.Toastify__toast-body {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
}

body::after {
  content: '';
  background: var(--bg2);
  clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  display: block;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
  left: 0;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .main-page-wrapper {
    margin: 0.5rem;
    padding-bottom: 1rem;
  }
}

@media only screen and (max-device-width: 767px) {
  .main-page-wrapper {
    margin: 0.5rem;
    padding-bottom: 1rem;
  }
}

@media only screen and (min-device-width: 1024px) {
  .main-page-wrapper {
    margin: 0.5rem;
    padding-bottom: 1rem;
  }
}

body:not(.dark-mode) {
  background: var(--light-bg1);
}

body:not(.dark-mode)::after {
  background: var(--light-bg2);
}

body:not(.dark-mode) .results-row,
body:not(.dark-mode) .page-title-block,
body:not(.dark-mode) .user-header,
body:not(.dark-mode) .prediction-card,
body:not(.dark-mode) .schedule-header,
body:not(.dark-mode) .schedule-card,
body:not(.dark-mode) .faq-item,
body:not(.dark-mode) .about-us > div,
body:not(.dark-mode) .charts .a-chart,
body:not(.dark-mode) .chart-waiting,
body:not(.dark-mode) .empty-row {
  background: var(--bg3);
  color: var(--light-text);
}

body:not(.dark-mode) .nav-bar a:not(.is-active),
body:not(.dark-mode) .name a,
body:not(.dark-mode) .stats > div:not(.rank) .label,
body:not(.dark-mode) .prediction-card > div,
body:not(.dark-mode) .bonus-question > div,
body:not(.dark-mode) .toggleSwitch > span,
body:not(.dark-mode) .match-time,
body:not(.dark-mode) .match-venue,
body:not(.dark-mode) .flag,
body:not(.dark-mode) .team-member a,
body:not(.dark-mode) .trendlines.a-chart .uplot .u-title,
body:not(.dark-mode) .toggle-legend label,
body:not(.dark-mode) .a-chart > h2,
body:not(.dark-mode) .u-series th,
body:not(.dark-mode) .u-series td {
  color: var(--light-text);
}

body:not(.dark-mode) .toggle--daynight .toggle--checkbox:checked + .toggle--btn {
  border-color: var(--light-bg1);
}

body:not(.dark-mode) .nav-bar,
body:not(.dark-mode) .results-row.header {
  background: var(--bg3-80);
  color: var(--light-text);
}

body:not(.dark-mode) .user-image {
  border-color: var(--axlOrange);
}

body:not(.dark-mode) .won svg path,
body:not(.dark-mode) .win svg path {
  fill: var(--axlOrange);
}

body:not(.dark-mode) .win.prediction svg path,
body:not(.dark-mode) .user-answer.win > svg:last-of-type path {
  fill: var(--light-bonus);
}

body:not(.dark-mode) .bonus svg path {
  fill: var(--light-bonus);
}

body:not(.dark-mode) .question svg path,
body:not(.dark-mode) .bonus-question svg path,
body:not(.dark-mode) .faq-item svg path {
  fill: var(--black-two);
}

body:not(.dark-mode) .b {
  background: var(--light-bonus);
  color: var(--pure-white);
}

body:not(.dark-mode) .a {
  background: var(--light-absent);
}

body:not(.dark-mode) .avatar .pos {
  color: var(--pos);
}

body:not(.dark-mode) .pos-1 {
  color: var(--pos-1);
}

body:not(.dark-mode) .pos-2 {
  color: var(--pos-2);
}

body:not(.dark-mode) .pos-3 {
  color: var(--pos-3) !important;
}

body:not(.dark-mode) .name-image h1 {
  color: var(--axlOrange);
  text-shadow: none;
}

body:not(.dark-mode) .wwcd {
  box-shadow: 0 19px 38px #9fb0ff, 0 15px 12px black;
}

body:not(.dark-mode) .bonus-question,
body:not(.dark-mode) .bonus-question > .question {
  border-color: var(--light-bg2);
}

body:not(.dark-mode) .last-updated {
  color: var(--light-bg1);
}

body:not(.dark-mode) .bonus .mobile-only-label {
  color: var(--light-bonus);
}

body:not(.dark-mode) .total-points .mobile-only-label {
  color: var(--bg2);
}

body:not(.dark-mode) .schedule-card.today .number {
  color: var(--axlOrange);
}

body.dark-mode .tournament-option input:checked ~ label {
  background-color: var(--silver);
  color: var(--pure-black);
}

body.dark-mode .tournament-option input ~ label svg path {
  fill: var(--bg1);
}

.copyright {
  padding-bottom: 2rem;
  display: block;
  font-size: 1rem;
  text-align: center;
  background: var(--bg3);
  color: var(--light-text);
  padding: 20px;
  box-shadow: 0px 0px 22px rgb(0 0 0 / 30%), 0px 0px 12px 0px rgb(0 0 0 / 22%);
  backdrop-filter: blur(5px);
  position: relative;
  border-radius: 0.625rem;
  margin-top: 1rem;
  font-weight: 600;
}

body.dark-mode .copyright {
  background: var(--header);
  color: var(--pure-white);
}
