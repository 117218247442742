.toggleSwitch {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggleSwitch > span {
  margin-right: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--black-three);
}

body.dark-mode .toggleSwitch > span {
  color: var(--pure-white);
}
