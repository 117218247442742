.a-chart {
  background: var(--header);
  box-shadow: 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%);
  margin-bottom: 0.75rem;
  border-radius: 0.625rem;
}

.help-text.chart-help {
  display: none;
}

.charts {
  position: relative;
}

.charts .toggle-legend {
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  align-items: center;
}

.charts .toggle-legend label {
  color: var(--pure-white);
  padding-left: 12px;
}

@media only screen and (max-device-width: 767px) {
  .help-text.chart-help {
    display: flex;
  }

  .charts .trendlines.a-chart,
  .charts .chart-waiting.trendlines {
    display: none;
  }
}

.a-chart > h2 {
  color: var(--pure-white);
  text-align: center;
  padding: 1rem 0;
  margin: 0;
}

.chart-waiting {
  background: var(--header);
  padding: 2rem;
  margin-bottom: 0.75rem;
  box-shadow: 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%);
  border-radius: 0.625rem;
}

.trendlines.a-chart {
  padding-bottom: 2rem;
}

.trendlines.a-chart .uplot.u-hz {
  margin: 0 auto;
}

.trendlines.a-chart .uplot .u-title {
  color: var(--pure-white);
  text-align: center;
  padding: 1rem 0;
  margin: 0;
  font-size: 1.5rem;
}

.u-series th,
.u-series td {
  font-weight: 600;
  color: var(--bg1);
  font-family: 'Quicksand';
}

table.u-legend {
  text-align: left;
  display: grid;
  grid-template-columns: repeat(5, 200px);
}

.u-series {
  margin: 0;
}

.u-series .u-label {
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.u-series:first-child {
  border: 1px solid var(--yellow);
}

.u-wrap > .u-axis {
  pointer-events: none;
}

/* User progress chart */
.user-progress-chart .a-chart {
  background: none;
  box-shadow: none;
  margin: 0;
}

.user-progress-chart .a-chart .u-title,
.user-progress-chart .a-chart .u-legend {
  display: none;
}