.filter-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  height: 50px;
  z-index: 3;
  border-radius: 0.625rem;
}

.filter-row > div {
  width: 170px;
}

.filter-row > div:first-child {
  margin-right: 1rem;
}

@media only screen and (max-device-width: 767px) {
  .filter-row > div {
    width: calc(50% - 0.5rem);
  }

  .filter-row > div:first-child {
    margin-right: 1rem;
  }
}

.s__control {
  background: var(--header) !important;
  border-radius: 10px !important;
  border: none !important;
  box-shadow: 0px 0px 22px rgb(0 0 0 / 30%), 0px 0px 12px 0px rgb(0 0 0 / 22%);
  backdrop-filter: blur(5px);
}

.s__control .s__placeholder {
  color: var(--s-menu-placeholder);
  font-weight: 600;
  font-size: 15px;
}

.s__control.s__control--menu-is-open {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.s__control + .s__menu {
  background-color: var(--header-90) !important;
  margin: 0;
  z-index: 3;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%);
}

.s__control + .s__menu .s__menu-list {
  padding: 0;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.s__option.s__option--is-focused {
  background-color: var(--bg2);
}

.s__option,
.s__single-value,
.s__input,
.s__input input {
  font-size: 15px;
  color: var(--pure-white) !important;
}

.s__indicator svg path {
  fill: var(--axlOrange);
}

body:not(.dark-mode) .s__control,
body:not(.dark-mode) .s__control + .s__menu {
  background: var(--s-menu) !important;
}

body:not(.dark-mode) .s__option,
body:not(.dark-mode) .s__single-value,
body:not(.dark-mode) .s__input,
body:not(.dark-mode) .s__input input {
  color: var(--pure-black) !important;
}

body:not(.dark-mode) .s__option.s__option--is-focused {
  background-color: var(--light-bg2);
}
