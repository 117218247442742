.schedule-table {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-gap: 2rem;
}

.schedule-header {
  background: var(--header);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 22px rgb(0 0 0 / 30%), 0px 0px 12px 0px rgb(0 0 0 / 22%);
  border-radius: 0.625rem;
  backdrop-filter: blur(5px);
}

.schedule-header.show-past + .schedule-table .past {
  display: block;
}

.schedule-table.waiting {
  display: block;
}

@media only screen and (max-device-width: 1024px) {
  .schedule-table {
    grid-gap: 0.5rem;
  }
}

@media only screen and (min-device-width: 1025px) {
  .schedule-table {
    margin-top: 1.5rem;
  }
}

.schedule-card {
  background: var(--header);
  padding: 1.25rem;
  position: relative;
  overflow: hidden;
  line-height: 1.5;
  box-shadow:  0px 0px 22px rgb(0 0 0 / 30%), 0px 0px 12px 0px rgb(0 0 0 / 22%);
  border-radius: 0.625rem;
  backdrop-filter: blur(5px);
}

.schedule-card.today .number {
  color: var(--yellow);
  text-shadow:  0 2px 8px rgb(0 0 0 / 25%);
}

.schedule-card.past {
  display: none;
}

.schedule-card > div {
  z-index: 2;
}

.number {
  color: var(--bg2-18);
  font-size: 8.6875rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -1rem;
  z-index: 1;
}

.match-name {
  color: var(--axlOrange);
  font-weight: bold;
  text-transform: capitalize;
  font-size: 1.25rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.match-time,
.match-venue {
  font-weight: 600;
  color: var(--gray);
}

.match-time svg,
.match-venue svg,
.match-result svg {
  padding-right: 0.5rem;
  width: 1rem !important;
}
.match-result > span {
  color: var(--axlOrange);
}

.match-result svg path {
  fill: var(--axlOrange);
}

.flag {
  display: flex;
  align-items: center;
  color: var(--gray);
}

/* IPL teams */

.flag > span.ipl,
span.team-icon.ipl {
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 58px;
  height: 58px;
  margin: 0 0.75rem;
}

.ipl.csk {
  background-image: url('./teams/csk.png');
}

.ipl.dc {
  background-image: url('./teams/dc.png');
}

.ipl.kkr {
  background-image: url('./teams/kkr.png');
}

.ipl.mi {
  background-image: url('./teams/mi.png');
}

.ipl.pk {
  background-image: url('./teams/pk.png');
}

.ipl.rr {
  background-image: url('./teams/rr.png');
}

.ipl.rcb {
  background-image: url('./teams/rcb.png');
}

.ipl.srh {
  background-image: url('./teams/srh.png');
}

.ipl.lsg {
  background-image: url('./teams/lsg.png');
}

.ipl.gt {
  background-image: url('./teams/gt.png');
}

.ipl.tba {
  background-image: url('./teamsprite.png');
  background-position: -231px -58px;
  background-size: auto !important;
}

/* National teams */
.flag > span.t20wc,
span.team-icon.t20wc,
.flag > span.wc,
span.team-icon.wc {
  background-image: url('./national.png');
  background-size: 400px 360px;
  width: 40px;
  height: 30px;
  margin: 0 0.75rem;
  display: inline-block;
}

.t20wc.ind,
.wc.ind {
  background-position: -200px -180px;
}

.t20wc.afg,
.wc.afg {
  background-position: -40px 0
}

.t20wc.ire,
.wc.ire {
  background-position: -40px -210px
}

.t20wc.nam,
.wc.nam {
  background-position: -240px -240px
}

.t20wc.ned,
.wc.ned {
  background-position: -280px -30px;
}

.t20wc.sl, .t20wc.srl,
.wc.sl, .wc.srl {
  background-position: -320px -210px
}

.t20wc.aus,
.wc.aus {
  background-position: 0 -30px
}

.t20wc.ban,
.wc.ban {
  background-position: -80px -30px
}

.t20wc.oma,
.wc.oma {
  background-position: -280px -150px
}

.t20wc.png,
.wc.png {
  background-position: -40px -270px;
}

.t20wc.sco,
.wc.sco {
  background-position: -320px -30px
}

.t20wc.eng,
.wc.eng {
  background-position: 0 -150px
}

.t20wc.sa, .t20wc.rsa,
.wc.sa, .wc.rsa {
  background-position: -280px -270px
}

.t20wc.wi,
.wc.wi {
  background-position: -200px -330px
}

.t20wc.nz,
.wc.nz {
  background-position: -280px -120px
}

.t20wc.zw,
.wc.zw {
  background-position: -320px -330px;
}

.t20wc.pak,
.wc.pak {
  background-position: -280px -180px
}

.t20wc.us,
.wc.us {
  background-image: url('./teams/usa.png') !important;
  background-size: contain !important;
  background-repeat: no-repeat;
}

.t20wc.can,
.wc.can {
  background-image: url('./teams/can.png') !important;
  background-size: contain !important;
  background-repeat: no-repeat;
}

.t20wc.nep,
.wc.nep {
  background-image: url('./teams/nep.png') !important;
  background-size: contain !important;
  background-repeat: no-repeat;
}

.t20wc.ug,
.wc.ug {
  background-image: url('./teams/ugn.png') !important;
  background-size: contain !important;
  background-repeat: no-repeat;
}

.t20wc.a1,
.t20wc.a2,
.t20wc.b1,
.t20wc.b2,
.wc.q1,
.wc.q2,
.wc.tba,
.tba {
  background-position: -360px -30px;
}
